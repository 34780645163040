var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("input", {
      ref: "input",
      staticClass: "issues__hidden",
      attrs: { name: "fileToUpload", type: "file", accept: ".png, .jpg, .bmp" },
      on: { change: _vm.onChange }
    }),
    !_vm.checkIssueIsOpen
      ? _c("p", { staticClass: "issues__error-tab" }, [
          _vm._v(" Issue status must be 'open' to attach a file ")
        ])
      : _vm._e(),
    _vm.templateIfNoAttachments
      ? _c(
          "div",
          {
            staticClass: "issues__tabs-attachments-container",
            class: _vm.classAccordingDrag(),
            style: _vm.checkIssueIsOpen
              ? "opacity: 1"
              : "opacity: 0.5; pointer-events: none",
            on: {
              dragenter: _vm.checkDraggable,
              dragleave: function($event) {
                _vm.checkDrag = false
              },
              dragexit: function($event) {
                _vm.checkDrag = false
              },
              dragover: function($event) {
                return _vm.allowDrop($event)
              },
              drop: function($event) {
                return _vm.dropFile($event)
              }
            }
          },
          [
            _c("img", {
              staticClass: "issues__image-icon",
              attrs: {
                src: require("@/assets/images/attachment-image-icon.png"),
                alt: "attach your image"
              },
              on: { click: _vm.browseFiles }
            }),
            _c("label", { staticClass: "issues__drop-text" }, [
              _vm._v(" Drag and drop files here "),
              _c(
                "span",
                {
                  staticClass: "issues__browse-text",
                  on: { click: _vm.browseFiles }
                },
                [_vm._v("or browse")]
              )
            ]),
            _c("div", { staticClass: "mt-6 issues__drop-text" }, [
              _c("label", [
                _vm._v(" Also you can "),
                _vm.screenShot
                  ? _c(
                      "span",
                      {
                        staticClass: "issues__browse-text",
                        on: { click: _vm.pasteScreenshot }
                      },
                      [_vm._v(" paste your screenshot ")]
                    )
                  : _c(
                      "span",
                      {
                        staticClass: "issues__browse-text",
                        on: { click: _vm.createScreenshot }
                      },
                      [_vm._v(" create a screenshot ")]
                    )
              ])
            ])
          ]
        )
      : _vm._e(),
    _vm.templateIfAttachmentsList
      ? _c(
          "div",
          { staticClass: "attachment-wrapper" },
          [
            _c(
              "div",
              {
                staticClass: "attachment-mini-drop-container",
                class: _vm.classAccordingDrag(),
                style: _vm.checkIssueIsOpen
                  ? "opacity: 1"
                  : "opacity: 0.5; pointer-events: none",
                on: {
                  click: _vm.browseFiles,
                  dragenter: _vm.checkDraggable,
                  dragleave: function($event) {
                    _vm.checkDrag = false
                  },
                  dragexit: function($event) {
                    _vm.checkDrag = false
                  },
                  dragover: function($event) {
                    return _vm.allowDrop($event)
                  },
                  drop: function($event) {
                    return _vm.dropFile($event)
                  }
                }
              },
              [
                _c("img", {
                  staticClass: "issues__image-icon",
                  attrs: {
                    src: require("@/assets/images/attachment-image-icon.png"),
                    alt: "attach your image"
                  }
                }),
                _c("span", [
                  _vm._v(" Drop Your Images here, browse "),
                  _c(
                    "span",
                    { staticClass: "bold", on: { click: _vm.pasteScreenshot } },
                    [_vm._v("or paste your screenshot")]
                  )
                ])
              ]
            ),
            _vm._l(_vm.attachments, function(attachment) {
              return _c(
                "div",
                {
                  key: attachment.id,
                  staticClass: "attachment-item",
                  on: {
                    click: function($event) {
                      return _vm.showAttachment(attachment)
                    }
                  }
                },
                [
                  attachment.attributes.url
                    ? _c("img", {
                        staticStyle: {
                          border: "1px solid gray",
                          height: "3rem",
                          width: "3rem"
                        },
                        attrs: {
                          src: attachment.base64,
                          alt: attachment.attributes.name
                        }
                      })
                    : _vm._e(),
                  _c("span", [_vm._v(_vm._s(attachment.attributes.name))]),
                  _c("small", [
                    _vm._v(
                      _vm._s(_vm.formatDate(attachment.attributes.created_at))
                    )
                  ])
                ]
              )
            }),
            _vm.dataForAttachmentViewer !== null
              ? _c(
                  "div",
                  { staticClass: "attachment-viewer" },
                  [
                    _c("h1", [
                      _vm._v(
                        _vm._s(_vm.dataForAttachmentViewer.attributes.name)
                      )
                    ]),
                    _c("MiniViewer", {
                      attrs: { urn: _vm.dataForAttachmentViewer.base64 }
                    })
                  ],
                  1
                )
              : _vm._e()
          ],
          2
        )
      : _c("div", { staticClass: "issues__image-container" }, [
          _vm.showFile
            ? _c("div", [
                _c("span", [_vm._v(_vm._s(_vm.uploadedFile.name))]),
                _c("img", {
                  staticClass: "issues__image-thumbnail",
                  attrs: {
                    alt: "click here to browse files",
                    src: _vm.imageSrc,
                    draggable: false
                  }
                })
              ])
            : _vm._e(),
          _vm.showScreenshot
            ? _c("img", {
                staticClass: "issues__image-thumbnail",
                attrs: { src: _vm.imageSrc, draggable: false }
              })
            : _vm._e(),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.imageName,
                expression: "imageName"
              }
            ],
            staticClass: "input",
            staticStyle: { width: "80%", margin: "auto" },
            attrs: { placeholder: "Attachment title" },
            domProps: { value: _vm.imageName },
            on: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.setAttachment($event)
              },
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.imageName = $event.target.value
              }
            }
          }),
          _c(
            "div",
            { staticClass: "issues__image-btn-row" },
            [
              _c("BButton", {
                staticClass: "has-background-danger has-text-white",
                attrs: { label: "cancel" },
                on: { click: _vm.cancelUploadImage }
              }),
              _c("BButton", {
                staticClass: "bim-button-attachments",
                class: _vm.accordingStatus,
                attrs: { label: "accept" },
                on: { click: _vm.setAttachment }
              })
            ],
            1
          )
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }