import { __decorate, __extends } from "tslib";
import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
var CreateIssueForm = (function (_super) {
    __extends(CreateIssueForm, _super);
    function CreateIssueForm() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(CreateIssueForm.prototype, "issueSubtypes", {
        get: function () {
            var _this = this;
            var type = this.issueTypes.find(function (issue) { return issue.id == _this.issueDraft.attributes.ng_issue_type_id; });
            return type.subtypes;
        },
        enumerable: false,
        configurable: true
    });
    CreateIssueForm.prototype.createIssue = function () { };
    __decorate([
        Prop({ default: false })
    ], CreateIssueForm.prototype, "loading", void 0);
    __decorate([
        Prop()
    ], CreateIssueForm.prototype, "currentState", void 0);
    __decorate([
        Prop({ default: [] })
    ], CreateIssueForm.prototype, "issueTypes", void 0);
    __decorate([
        Prop()
    ], CreateIssueForm.prototype, "issueDraft", void 0);
    __decorate([
        Prop()
    ], CreateIssueForm.prototype, "selection", void 0);
    __decorate([
        Emit()
    ], CreateIssueForm.prototype, "createIssue", null);
    CreateIssueForm = __decorate([
        Component
    ], CreateIssueForm);
    return CreateIssueForm;
}(Vue));
export default CreateIssueForm;
