var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "issues__wrapper" },
    [
      _c(
        "BLoading",
        { attrs: { "is-full-page": false, active: _vm.loading } },
        [_c("p", [_vm._v("Creating issue...")])]
      ),
      _c(
        "form",
        {
          staticClass: "issues__container-form",
          staticStyle: { padding: "10px" }
        },
        [
          _c(
            "BField",
            {
              staticClass: "mb-6",
              attrs: { label: "Title", "label-position": "on-border" }
            },
            [
              _c("BInput", {
                attrs: { placeholder: "The issue title..." },
                model: {
                  value: _vm.issueDraft.attributes.title,
                  callback: function($$v) {
                    _vm.$set(_vm.issueDraft.attributes, "title", $$v)
                  },
                  expression: "issueDraft.attributes.title"
                }
              })
            ],
            1
          ),
          _c(
            "BField",
            {
              staticClass: "mb-6",
              attrs: { label: "Description", "label-position": "on-border" }
            },
            [
              _c("BInput", {
                attrs: { placeholder: "The issue description..." },
                model: {
                  value: _vm.issueDraft.attributes.description,
                  callback: function($$v) {
                    _vm.$set(_vm.issueDraft.attributes, "description", $$v)
                  },
                  expression: "issueDraft.attributes.description"
                }
              })
            ],
            1
          ),
          _c(
            "BField",
            {
              staticClass: "mb-6",
              attrs: { label: "Status", "label-position": "on-border" }
            },
            [
              _c(
                "BSelect",
                {
                  attrs: { placeholder: "Select an issue status" },
                  model: {
                    value: _vm.issueDraft.attributes.status,
                    callback: function($$v) {
                      _vm.$set(_vm.issueDraft.attributes, "status", $$v)
                    },
                    expression: "issueDraft.attributes.status"
                  }
                },
                [
                  _c("option", { attrs: { value: "draft" } }, [
                    _vm._v("Draft")
                  ]),
                  _c("option", { attrs: { value: "open" } }, [_vm._v("Open")])
                ]
              )
            ],
            1
          ),
          _c(
            "BField",
            {
              staticClass: "mb-6",
              attrs: { label: "Type", "label-position": "on-border" }
            },
            [
              _c(
                "BSelect",
                {
                  attrs: { placeholder: "Issue type" },
                  model: {
                    value: _vm.issueDraft.attributes.ng_issue_type_id,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.issueDraft.attributes,
                        "ng_issue_type_id",
                        $$v
                      )
                    },
                    expression: "issueDraft.attributes.ng_issue_type_id"
                  }
                },
                _vm._l(_vm.issueTypes, function(type) {
                  return _c(
                    "option",
                    {
                      key: type.id,
                      attrs: { disabled: !type.isActive },
                      domProps: { value: type.id }
                    },
                    [_vm._v(" " + _vm._s(type.title) + " ")]
                  )
                }),
                0
              )
            ],
            1
          ),
          _c(
            "BField",
            { attrs: { label: "Subtype", "label-position": "on-border" } },
            [
              _c(
                "BSelect",
                {
                  attrs: { placeholder: "Issue subtype" },
                  model: {
                    value: _vm.issueDraft.attributes.ng_issue_subtype_id,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.issueDraft.attributes,
                        "ng_issue_subtype_id",
                        $$v
                      )
                    },
                    expression: "issueDraft.attributes.ng_issue_subtype_id"
                  }
                },
                [
                  _vm.issueDraft.attributes.ng_issue_type_id === null
                    ? _c("option", { attrs: { value: "open" } }, [
                        _vm._v(" Select an issue type first ")
                      ])
                    : _vm._l(_vm.issueSubtypes, function(type) {
                        return _c(
                          "option",
                          { key: type.id, domProps: { value: type.id } },
                          [_vm._v(" " + _vm._s(type.title) + " ")]
                        )
                      })
                ],
                2
              )
            ],
            1
          ),
          _c(
            "BButton",
            {
              staticClass: "mt-6",
              attrs: { disabled: _vm.selection === 0 },
              on: { click: _vm.createIssue }
            },
            [_vm._v("Create Issue")]
          ),
          !_vm.selection
            ? _c("span", [_vm._v("You have to select some item at Model")])
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }