import { __awaiter, __decorate, __extends, __generator } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import ActivityItem from '@/components/autodeskForge/Bim360IssueViewer/IssueDetail/ActivityItem.vue';
var ActivityTab = (function (_super) {
    __extends(ActivityTab, _super);
    function ActivityTab() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.issue = _this.$props.issueData;
        _this.activities = [];
        return _this;
    }
    ActivityTab.prototype.beforeMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.takeIssueActivity()];
                    case 1:
                        _a.sent();
                        return [2];
                }
            });
        });
    };
    ActivityTab.prototype.takeIssueActivity = function () {
        return __awaiter(this, void 0, void 0, function () {
            var data, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4, this.$functions.httpsCallable('getIssueActivity')({
                                issue: this.issue,
                                userId: this.$store.state.user.uid
                            })];
                    case 1:
                        data = (_a.sent()).data;
                        this.activities = data;
                        console.log('this.activities', this.activities);
                        return [3, 3];
                    case 2:
                        error_1 = _a.sent();
                        console.log('error', error_1);
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    };
    ActivityTab = __decorate([
        Component({
            components: { ActivityItem: ActivityItem },
            props: ['issueData']
        })
    ], ActivityTab);
    return ActivityTab;
}(Vue));
export default ActivityTab;
