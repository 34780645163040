var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "issue-viewer" },
    [
      _vm.mode !== 2
        ? _c(
            "div",
            { staticClass: "pt-2" },
            [
              _c(
                "BButton",
                {
                  attrs: {
                    "icon-left": _vm.mode === 0 ? "plus" : "chevron-left",
                    size: "is-small ml-2"
                  },
                  on: {
                    click: function($event) {
                      _vm.mode = _vm.mode === 0 ? 1 : 0
                    }
                  }
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.mode === 0 ? "New issue" : "Back") + " "
                  )
                ]
              ),
              _vm.noSelectionAtModel()
                ? _c("p", { staticClass: "issues__error-tab" }, [
                    _vm._v(
                      " Please select an object in the model to create an issue. "
                    )
                  ])
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.mode === 0
        ? _c("IssueCardList", {
            attrs: { issues: _vm.issues, meta: _vm.meta, loading: _vm.loading },
            on: {
              "on-page-change": _vm.onPageChange,
              "view-issue-clicked": _vm.viewIssueClicked,
              detail: _vm.detail
            }
          })
        : _vm._e(),
      _vm.mode === 1
        ? _c("CreateIssueForm", {
            attrs: {
              loading: _vm.loading,
              "current-state": _vm.currentState,
              "issue-types": _vm.issueTypes,
              "issue-draft": _vm.issueDraft,
              selection: _vm.selection
            },
            on: { "create-issue": _vm.createIssue }
          })
        : _vm._e(),
      _vm.mode === 2
        ? _c("IssueDetail", {
            attrs: { issue: _vm.issueForDetail, "screen-shot": _vm.screenShot },
            on: {
              back: function($event) {
                _vm.mode = 0
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }