import { __awaiter, __decorate, __extends, __generator } from "tslib";
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Box3, Vector3 } from 'three';
import IssueCardList from '@/components/autodeskForge/Bim360IssueViewer/IssueCardList.vue';
import CreateIssueForm from '@/components/autodeskForge/Bim360IssueViewer/CreateIssueForm.vue';
import IssueDetail from '@/components/autodeskForge/Bim360IssueViewer/IssueDetail.vue';
var BimIssueViewer = (function (_super) {
    __extends(BimIssueViewer, _super);
    function BimIssueViewer() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.issueTypes = [];
        _this.issues = null;
        _this.meta = null;
        _this.mode = 0;
        _this.loading = true;
        _this.issueForDetail = null;
        _this.issueDraft = {
            type: 'quality_issues',
            attributes: {
                title: '',
                ng_issue_type_id: null,
                ng_issue_subtype_id: null,
                description: undefined,
                due_date: undefined,
                status: 'draft',
                starting_version: 1
            }
        };
        _this.onSelectionChanged = function () {
        };
        return _this;
    }
    Object.defineProperty(BimIssueViewer.prototype, "selection", {
        get: function () {
            return this.currentState.objectSet[0].id.length;
        },
        enumerable: false,
        configurable: true
    });
    BimIssueViewer.prototype.onUrnChanged = function (oldVal, newVal) {
        var _this = this;
        if (newVal !== null) {
            this.getIssues().then(function () { return _this.displayPushpinIssues(); });
            this.getIssueTypes();
            this.displayPushpinIssues();
        }
    };
    BimIssueViewer.prototype.onViewerChanged = function (oldVal, newVal) {
        if (newVal !== null) {
            this.displayPushpinIssues();
        }
    };
    BimIssueViewer.prototype.onPageChange = function (value) {
        var _this = this;
        this.meta.page.offset = value - 1;
        this.getIssues().then(function () { return _this.displayPushpinIssues(); });
    };
    BimIssueViewer.prototype.getPushpinExtension = function () {
        return __awaiter(this, void 0, void 0, function () {
            var pushPinExtension, extensionOptions;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.viewer)
                            throw Error('Viewer not ready yet');
                        pushPinExtension = this.viewer.viewer.getExtension('Autodesk.BIM360.Extension.PushPin');
                        if (!(pushPinExtension == null))
                            return [3, 2];
                        extensionOptions = {
                            hideRfisButton: true,
                            hideFieldIssuesButton: true
                        };
                        return [4, this.viewer.viewer.loadExtension('Autodesk.BIM360.Extension.PushPin', extensionOptions)];
                    case 1: return [2, _a.sent()];
                    case 2: return [2, pushPinExtension];
                }
            });
        });
    };
    BimIssueViewer.prototype.mounted = function () {
        var _this = this;
        if (this.viewer && this.viewer.viewer) {
            this.viewer.viewer.addEventListener(window.Autodesk.Viewing.SELECTION_CHANGED_EVENT, this.onSelectionChanged);
        }
        this.getIssues().then(function () { return _this.displayPushpinIssues(); });
        this.getIssueTypes();
    };
    BimIssueViewer.prototype.viewIssueClicked = function (issue) {
        this.$emit('change-state', issue.attributes.pushpin_attributes.viewer_state);
        this.viewer.setState(issue.attributes.pushpin_attributes.viewer_state);
    };
    BimIssueViewer.prototype.getIssues = function () {
        var _this = this;
        var _a;
        this.loading = true;
        return this.$functions
            .httpsCallable('getIssues')({
            containerId: this.containerId,
            targetUrn: this.modelUrn,
            meetId: this.meetId,
            pageLimit: 10,
            pageOffset: ((_a = this.meta) === null || _a === void 0 ? void 0 : _a.page.offset) || 0
        })
            .then(function (result) {
            _this.issues = result.data.data;
            _this.meta = result.data.meta;
            _this.loading = false;
        });
    };
    BimIssueViewer.prototype.getModifiedWorldBoundingBoxCenter = function (fragIds) {
        try {
            var fragList_1 = this.viewer.viewer.model.getFragmentList();
            var fragbBox_1 = new Box3();
            var nodebBox_1 = new Box3();
            fragIds.forEach(function (fragId) {
                fragList_1.getWorldBounds(fragId, fragbBox_1);
                nodebBox_1.union(fragbBox_1);
            });
            var center = new Vector3();
            nodebBox_1.getCenter(center);
            return center;
        }
        catch (error) {
            console.log('TCL: BimIssueViewer -> getModifiedWorldBoundingBoxCenter -> error', error);
        }
    };
    BimIssueViewer.prototype.getIssueTypes = function () {
        var _this = this;
        return this.$functions
            .httpsCallable('getIssueTypes')({ containerId: this.containerId })
            .then(function (result) {
            _this.issueTypes = result.data.results;
        })
            .catch(function (err) { return console.error(err); });
    };
    BimIssueViewer.prototype.displayPushpinIssues = function () {
        var _this = this;
        this.getPushpinExtension()
            .then(function (pushPinExtension) {
            pushPinExtension.removeAllItems();
            pushPinExtension.showAll();
            var existingListener = pushPinExtension.pushPinManager.listeners['pushpin.selected'][0];
            pushPinExtension.pushPinManager.removeEventListener('pushpin.selected', existingListener.callbackFn);
            pushPinExtension.pushPinManager.addEventListener('pushpin.selected', function (e) {
                if (e.value) {
                    console.warn('pushpin was selected!', e.value.data);
                    _this.viewer.setState(e.value.data.viewerState, false);
                }
            });
            var pushpinDataArray = [];
            _this.issues.forEach(function (issue) {
                if (issue.attributes.status == 'open' || issue.attributes.status == 'draft') {
                    var issueAttributes = issue.attributes;
                    var pushpinAttributes = issue.attributes.pushpin_attributes;
                    if (pushpinAttributes) {
                        issue.type = issue.type.replace('quality_', '');
                        pushpinDataArray.push({
                            id: issue.id,
                            label: issueAttributes.identifier,
                            status: issue.type && issueAttributes.status.indexOf(issue.type) === -1
                                ? issue.type + "-" + issueAttributes.status
                                : issueAttributes.status,
                            position: pushpinAttributes.location,
                            type: issue.type,
                            objectId: pushpinAttributes.object_id[0],
                            viewerState: pushpinAttributes.viewer_state
                        });
                    }
                }
            });
            pushPinExtension.loadItems(pushpinDataArray);
        })
            .catch(function (err) {
            console.warn('viewer or issue extension not ready yet', err);
        });
    };
    BimIssueViewer.prototype.detail = function (issue) {
        this.mode = 2;
        this.issueForDetail = issue;
    };
    BimIssueViewer.prototype.noSelectionAtModel = function () {
        return this.mode === 1 && this.selection === 0;
    };
    BimIssueViewer.prototype.createIssue = function () {
        var _this = this;
        this.loading = true;
        var sheetGuid = this.viewer.viewer.model.getDocumentNode().data.guid;
        var sheetName = this.viewer.viewer.model.getDocumentNode().data.name;
        var fragIds = [];
        this.viewer.viewer.getSelection().forEach(function (dbId) {
            _this.viewer.viewer.model
                .getInstanceTree()
                .enumNodeFragments(dbId, function (fragId) { return fragIds.push(fragId); });
        });
        var x = {
            target_urn: this.modelUrn.split('?')[0],
            sheet_metadata: {
                is3D: this.viewer.viewer.model.is3d(),
                sheetGuid: sheetGuid,
                sheetName: sheetName
            },
            pushpin_attributes: {
                type: 'TwoDVectorPushpin',
                object_id: this.viewer.viewer.getSelection(),
                location: this.getModifiedWorldBoundingBoxCenter(fragIds),
                viewer_state: this.viewer.viewer.getState()
            }
        };
        Object.assign(this.issueDraft.attributes, x);
        this.$functions
            .httpsCallable('createIssue')({
            containerId: this.containerId,
            issue: this.issueDraft
        })
            .then(function () {
            console.log('created issue');
            _this.issueDraft = {
                type: 'quality_issues',
                attributes: {
                    title: '',
                    ng_issue_type_id: null,
                    ng_issue_subtype_id: null,
                    description: undefined,
                    due_date: undefined,
                    status: 'draft',
                    starting_version: 1
                }
            };
            _this.loading = false;
            return _this.getIssues().then(function () { return _this.displayPushpinIssues(); });
        })
            .then(function () {
            _this.mode = 0;
        })
            .catch(function (err) {
            console.error(err);
            _this.mode = 0;
        });
    };
    __decorate([
        Prop()
    ], BimIssueViewer.prototype, "containerId", void 0);
    __decorate([
        Prop()
    ], BimIssueViewer.prototype, "modelUrn", void 0);
    __decorate([
        Prop()
    ], BimIssueViewer.prototype, "meetId", void 0);
    __decorate([
        Prop()
    ], BimIssueViewer.prototype, "viewer", void 0);
    __decorate([
        Prop()
    ], BimIssueViewer.prototype, "currentState", void 0);
    __decorate([
        Prop()
    ], BimIssueViewer.prototype, "screenShot", void 0);
    __decorate([
        Watch('modelUrn')
    ], BimIssueViewer.prototype, "onUrnChanged", null);
    __decorate([
        Watch('viewer')
    ], BimIssueViewer.prototype, "onViewerChanged", null);
    BimIssueViewer = __decorate([
        Component({
            components: { IssueDetail: IssueDetail, IssueCardList: IssueCardList, CreateIssueForm: CreateIssueForm }
        })
    ], BimIssueViewer);
    return BimIssueViewer;
}(Vue));
export default BimIssueViewer;
