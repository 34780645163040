var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("span", { staticClass: "issues__concept-label" }, [
      _vm._v(_vm._s(_vm.label))
    ]),
    _c("p", { staticClass: "issues__concept-value" }, [
      _vm._v(_vm._s(_vm.value))
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }