import { __decorate, __extends } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import DetailConcept from '@/components/autodeskForge/Bim360IssueViewer/IssueDetail/DetailConcept.vue';
import Moment from 'moment';
var DetailsTab = (function (_super) {
    __extends(DetailsTab, _super);
    function DetailsTab() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.attributes = _this.$props.issueData.attributes;
        return _this;
    }
    DetailsTab.prototype.mounted = function () {
        console.log('issue-data =>', this.$props.issueData);
    };
    DetailsTab.prototype.formatDate = function (date) {
        return Moment(date).format('DD/MM/YYYY hh:mm:ss a');
    };
    DetailsTab = __decorate([
        Component({
            components: { DetailConcept: DetailConcept },
            props: ['issueData']
        })
    ], DetailsTab);
    return DetailsTab;
}(Vue));
export default DetailsTab;
