import { __decorate, __extends } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
var DetailConcept = (function (_super) {
    __extends(DetailConcept, _super);
    function DetailConcept() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        Prop({ default: '' })
    ], DetailConcept.prototype, "label", void 0);
    __decorate([
        Prop({ default: '' })
    ], DetailConcept.prototype, "value", void 0);
    DetailConcept = __decorate([
        Component
    ], DetailConcept);
    return DetailConcept;
}(Vue));
export default DetailConcept;
