import { __decorate, __extends } from "tslib";
import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import ActivityTab from '@/components/autodeskForge/Bim360IssueViewer/IssueDetail/ActivityTab.vue';
import AttachmentsTab from '@/components/autodeskForge/Bim360IssueViewer/IssueDetail/AttachmentsTab.vue';
import DetailsTab from '@/components/autodeskForge/Bim360IssueViewer/IssueDetail/DetailsTab.vue';
var IssueDetail = (function (_super) {
    __extends(IssueDetail, _super);
    function IssueDetail() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.backText = '<';
        _this.menu = ['details', 'attachments',];
        _this.selected = _this.menu[0];
        _this.showError = false;
        return _this;
    }
    IssueDetail.prototype.isActive = function (item) {
        return item === this.selected ? 'issues__detail-menu-active' : 'issues__detail-menu';
    };
    IssueDetail.prototype.getTab = function () {
        this.showError = false;
        switch (this.selected) {
            case this.menu[0]:
                return DetailsTab;
            case this.menu[1]:
                if (this.issue.attributes.status === 'draft') {
                    this.showError = true;
                }
                return AttachmentsTab;
        }
    };
    IssueDetail.prototype.back = function () { };
    __decorate([
        Prop()
    ], IssueDetail.prototype, "issue", void 0);
    __decorate([
        Prop()
    ], IssueDetail.prototype, "screenShot", void 0);
    __decorate([
        Emit()
    ], IssueDetail.prototype, "back", null);
    IssueDetail = __decorate([
        Component({
            components: { DetailsTab: DetailsTab, AttachmentsTab: AttachmentsTab, ActivityTab: ActivityTab }
        })
    ], IssueDetail);
    return IssueDetail;
}(Vue));
export default IssueDetail;
