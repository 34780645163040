import { __awaiter, __decorate, __extends, __generator } from "tslib";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
var Autodesk = window.Autodesk;
var Viewer = (function (_super) {
    __extends(Viewer, _super);
    function Viewer() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.viewer = null;
        _this.activeItem = null;
        _this.viewerConfig = {
            name: 'story-view-mode-settings',
            settings: {
                ambientShadows: true,
                groundShadow: false,
                optimizeNavigation: false,
                antiAliasing: true,
                displayLines: false,
                displayPoints: false,
                ghostHidden: true,
                progressiveDisplay: false,
                swapBlackAndWhite: false,
                lightPreset: 'Boardwalk'
            },
            extensions: {
                load: ['Autodesk.Viewing.ZoomWindow', 'Autodesk.CrossFadeEffects'],
                unload: [
                    'Autodesk.FullScreen',
                    'Autodesk.BimWalk',
                    'Autodesk.Explode',
                    'Autodesk.PropertiesManager'
                ]
            }
        };
        return _this;
    }
    Viewer.prototype.mounted = function () {
        this.useImageViewer();
    };
    Viewer.prototype.initViewer = function () {
        return __awaiter(this, void 0, void 0, function () {
            var viewer, _a, access_token, expires_in, options;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4, this.forgeTwoLeggedToken()];
                    case 1:
                        _a = _b.sent(), access_token = _a.access_token, expires_in = _a.expires_in;
                        options = {
                            env: 'AutodeskProduction',
                            api: 'derivativeV2_EU',
                            getAccessToken: function (onTokenReady) {
                                var token = access_token;
                                var timeInSeconds = expires_in;
                                onTokenReady(token, timeInSeconds);
                            }
                        };
                        Autodesk.Viewing.Initializer(options, function () {
                            var htmlDiv = document.getElementById('mini-viewer');
                            viewer = new Autodesk.Viewing.GuiViewer3D(htmlDiv);
                            var startedCode = viewer.start();
                            if (startedCode > 0) {
                                console.error('Failed to create a Viewer: WebGL not supported.');
                                return;
                            }
                            console.log('Initialization complete, loading a model next...');
                        });
                        return [4, this.renderModelUrn()];
                    case 2:
                        _b.sent();
                        return [2];
                }
            });
        });
    };
    Viewer.prototype.forgeTwoLeggedToken = function () {
        return __awaiter(this, void 0, void 0, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.$functions.httpsCallable('forgeTwoLeggedToken')({})];
                    case 1:
                        data = (_a.sent()).data;
                        return [2, data];
                }
            });
        });
    };
    Viewer.prototype.renderModelUrn = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                console.log('this.urn', '' + btoa(this.urn).replace('=', ''));
                Autodesk.Viewing.Document.load("'urn:' + " + btoa(this.urn).replace('=', ''), function (viewerDoc) {
                    var root = viewerDoc.getRoot();
                    var model = root.getDefaultGeometry();
                    _this.viewer.loadDocumentNode(viewerDoc, model);
                    _this.viewerDocument = viewerDoc;
                    _this.activeItem = model.data;
                    _this.$emit('model-loaded');
                }, function (errCode, errMsg) {
                    console.log('error', errCode, errMsg);
                    console.log('error', errMsg.toString());
                });
                return [2];
            });
        });
    };
    Viewer.prototype.renderModel = function () {
        var img = document.getElementById('imgBase64');
        img.src = this.urn;
        console.log('urn', this.urn);
    };
    Viewer.prototype.changeUrn = function () {
        this.useImageViewer();
    };
    Viewer.prototype.useImageViewer = function () {
        this.renderModel();
    };
    __decorate([
        Prop({ default: '' })
    ], Viewer.prototype, "urn", void 0);
    __decorate([
        Prop({ default: '' })
    ], Viewer.prototype, "viewerGuid", void 0);
    __decorate([
        Watch('urn')
    ], Viewer.prototype, "changeUrn", null);
    Viewer = __decorate([
        Component
    ], Viewer);
    return Viewer;
}(Vue));
export default Viewer;
