var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "issues__wrapper" },
    [
      _c("BLoading", { attrs: { "is-full-page": false, active: _vm.loading } }),
      _c("div", { staticClass: "issues__container scroll-bar" }, [
        _vm.issues && _vm.issues.length === 0
          ? _c("div", { staticClass: "issues__no-issues" }, [
              _vm._v(" This document has no issues. ")
            ])
          : _vm._e(),
        _vm.checkIsDraftOrOpen
          ? _c(
              "div",
              _vm._l(_vm.issues, function(issue, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "issues__card-item",
                    on: {
                      click: function($event) {
                        return _vm.detail(issue)
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "issues__card-row" },
                      [
                        _c(
                          "BTag",
                          {
                            staticClass: "issues__card-tag",
                            attrs: { type: _vm.getTagType(issue) }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  issue.attributes.status === "void"
                                    ? "—"
                                    : issue.attributes.status
                                ) +
                                " "
                            )
                          ]
                        ),
                        _c("span", { staticClass: "issues__card-date" }, [
                          _vm._v(
                            _vm._s(_vm.formatDate(issue.attributes.created_at))
                          )
                        ])
                      ],
                      1
                    ),
                    _c("span", { staticClass: "issues__card-title" }, [
                      _c("strong", [
                        _vm._v("#" + _vm._s(issue.attributes.identifier))
                      ]),
                      _vm._v(" - " + _vm._s(issue.attributes.title) + " ")
                    ]),
                    _c("span", { staticClass: "issues__card-texts" }, [
                      _vm._v(_vm._s(issue.attributes.description))
                    ]),
                    _c("span", { staticClass: "issues__card-texts mb-4" }, [
                      _vm._v(
                        " Location: " +
                          _vm._s(issue.attributes.location_description) +
                          " "
                      )
                    ]),
                    _c(
                      "div",
                      { staticClass: "issues__card-buttons-row" },
                      [
                        _c(
                          "BButton",
                          {
                            attrs: {
                              "icon-left": "comment",
                              type: "is-small",
                              disabled: "disabled"
                            }
                          },
                          [
                            _vm._v(
                              " " + _vm._s(issue.attributes.comment_count) + " "
                            )
                          ]
                        ),
                        _c(
                          "BButton",
                          {
                            attrs: {
                              "icon-left": "clipboard",
                              type: "is-small",
                              disabled: "disabled"
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(issue.attributes.attachment_count) +
                                " "
                            )
                          ]
                        ),
                        _c("BButton", {
                          attrs: { "icon-left": "eye", type: "is-small" },
                          on: {
                            click: function($event) {
                              return _vm.viewIssueClicked(issue)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              }),
              0
            )
          : _vm._e()
      ]),
      _vm.meta
        ? _c("BPagination", {
            staticClass: "no-bullets",
            attrs: {
              total: _vm.record,
              "per-page": _vm.meta.page.limit,
              current: _vm.meta.page.offset + 1,
              size: "is-small"
            },
            on: {
              "update:current": function($event) {
                return _vm.$set(_vm.meta.page, "offset + 1", $event)
              },
              change: _vm.onPageChange
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }