import { __decorate, __extends } from "tslib";
import { Vue, Component, Emit, Prop } from 'vue-property-decorator';
import Moment from 'moment';
var IssueCardList = (function (_super) {
    __extends(IssueCardList, _super);
    function IssueCardList() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.record = 0;
        return _this;
    }
    IssueCardList.prototype.mounted = function () {
        this.meta && (this.record = this.meta.record_count);
    };
    IssueCardList.prototype.checkIsDraftOrOpen = function () {
        return this.issues.some(function (issue) {
            return issue.attributes.status === 'open' || issue.attributes.status === 'draft';
        });
    };
    IssueCardList.prototype.getTagType = function (issue) {
        return issue.attributes.target_urn === null ? 'is-primary' : 'is-link';
    };
    IssueCardList.prototype.formatDate = function (date) {
        return Moment(date).format('DD/MM/YYYY');
    };
    IssueCardList.prototype.viewIssueClicked = function (issue) {
        return issue;
    };
    IssueCardList.prototype.detail = function (issue) {
        return issue;
    };
    IssueCardList.prototype.onPageChange = function () { };
    __decorate([
        Prop()
    ], IssueCardList.prototype, "issues", void 0);
    __decorate([
        Prop()
    ], IssueCardList.prototype, "meta", void 0);
    __decorate([
        Prop()
    ], IssueCardList.prototype, "loading", void 0);
    __decorate([
        Emit()
    ], IssueCardList.prototype, "viewIssueClicked", null);
    __decorate([
        Emit()
    ], IssueCardList.prototype, "detail", null);
    __decorate([
        Emit()
    ], IssueCardList.prototype, "onPageChange", null);
    IssueCardList = __decorate([
        Component
    ], IssueCardList);
    return IssueCardList;
}(Vue));
export default IssueCardList;
