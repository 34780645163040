import { __decorate, __extends } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
var ActivityItem = (function (_super) {
    __extends(ActivityItem, _super);
    function ActivityItem() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        Prop()
    ], ActivityItem.prototype, "activity", void 0);
    ActivityItem = __decorate([
        Component
    ], ActivityItem);
    return ActivityItem;
}(Vue));
export default ActivityItem;
