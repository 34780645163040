var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "issues__container" },
    [
      _c("div", { staticClass: "issues__detail-header" }, [
        _c("div", { staticClass: "issues__card-row" }, [
          _c(
            "span",
            { staticClass: "issues__detail-back-btn", on: { click: _vm.back } },
            [_vm._v(_vm._s(_vm.backText))]
          ),
          _c("span", { staticClass: "issues__status-span" }, [
            _vm._v(_vm._s(_vm.issue.attributes.status))
          ])
        ]),
        _c("p", { staticClass: "pa-4" }, [_vm._v("Issue")]),
        _c("h1", { staticClass: "issues__detail-index ma-4" }, [
          _vm._v("#" + _vm._s(_vm.issue.attributes.identifier))
        ])
      ]),
      _c(
        "div",
        { staticClass: "issues__detail-menu-row" },
        _vm._l(_vm.menu, function(item) {
          return _c(
            "span",
            {
              key: item,
              class: _vm.isActive(item),
              on: {
                click: function($event) {
                  _vm.selected = item
                }
              }
            },
            [_vm._v(" " + _vm._s(item) + " ")]
          )
        }),
        0
      ),
      _c(_vm.getTab(), {
        tag: "Component",
        attrs: { issueData: _vm.issue, "screen-shot": _vm.screenShot }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }