import { __awaiter, __generator } from "tslib";
export default (function (data, functions) {
    return __awaiter(void 0, void 0, void 0, function () {
        var error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4, functions.httpsCallable('screenshotToIssue')(data)];
                case 1: return [2, _a.sent()];
                case 2:
                    error_1 = _a.sent();
                    throw new Error(error_1);
                case 3: return [2];
            }
        });
    });
});
