var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.activities.length
    ? _c(
        "div",
        _vm._l(_vm.activities, function(activity, index) {
          return _c(
            "div",
            { key: index },
            [_c("ActivityItem", { attrs: { activity: activity } })],
            1
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }