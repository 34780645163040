var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pa-4" },
    [
      _c("p", { staticClass: "issues__detail-title" }, [
        _vm._v(_vm._s(_vm.attributes.title))
      ]),
      _c("p", [
        _vm._v(
          "Created at " + _vm._s(_vm.formatDate(_vm.attributes.created_at))
        )
      ]),
      _c("DetailConcept", {
        attrs: { label: "description", value: _vm.attributes.description }
      }),
      _c("DetailConcept", {
        attrs: {
          label: "custom attributes",
          value: _vm.attributes.custom_attributes[0].title
        }
      }),
      _c("DetailConcept", {
        attrs: { label: "assigned to", value: _vm.attributes.assigned_to }
      }),
      _c("DetailConcept", {
        attrs: {
          label: "last updated",
          value: _vm.formatDate(_vm.attributes.updated_at)
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }