import { __assign, __awaiter, __decorate, __extends, __generator } from "tslib";
import { Component, Vue, Watch } from 'vue-property-decorator';
import mixins from '@/components/meeting/ViewerDrawer/mixins/index';
import screenshotToIssue from '@/api/issues/screenshotToIssue';
import { ForgeCalls } from '@/components/meeting/ViewerDrawer/mixins/api/helpers';
import axios from 'axios';
import helpers from '@/components/meeting/ViewerDrawer/helpers';
import Moment from 'moment';
import MiniViewer from '@/components/autodeskForge/Bim360IssueViewer/IssueDetail/MiniViewer.vue';
var AttachmentsTab = (function (_super) {
    __extends(AttachmentsTab, _super);
    function AttachmentsTab() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.checkDrag = false;
        _this.uploadedFile = null;
        _this.showFile = false;
        _this.showScreenshot = false;
        _this.imageSrc = '';
        _this.issue = _this.$props.issueData;
        _this.screenShot = _this.$props.screenShot;
        _this.imageName = '';
        _this.clicked = false;
        _this.attachments = [];
        _this.showAttachmentViewer = false;
        _this.dataForAttachmentViewer = null;
        _this.uploadUrn = '';
        return _this;
    }
    AttachmentsTab.prototype.beforeMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.takeIssueAttachments()];
                    case 1:
                        _a.sent();
                        return [2];
                }
            });
        });
    };
    AttachmentsTab.prototype.mounted = function () {
        var _this = this;
        this.$root.$on('pasteScreenshot', function (blobObtained) {
            if (blobObtained !== undefined) {
                _this.imageSrc = blobObtained;
                _this.showScreenshot = true;
            }
        });
    };
    AttachmentsTab.prototype.takeIssueAttachments = function () {
        return __awaiter(this, void 0, void 0, function () {
            var data, _i, data_1, attachment, imageResponse, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 6, , 7]);
                        return [4, this.$functions.httpsCallable('getIssueAttachments')({
                                issue: this.issue,
                                userId: this.$store.state.user.uid
                            })];
                    case 1:
                        data = (_a.sent()).data;
                        _i = 0, data_1 = data;
                        _a.label = 2;
                    case 2:
                        if (!(_i < data_1.length))
                            return [3, 5];
                        attachment = data_1[_i];
                        console.log('attachment', attachment);
                        if (!(attachment.attributes.url !== null))
                            return [3, 4];
                        return [4, this.getImagePermission(attachment.attributes.url)];
                    case 3:
                        imageResponse = _a.sent();
                        attachment.base64 = imageResponse;
                        _a.label = 4;
                    case 4:
                        _i++;
                        return [3, 2];
                    case 5:
                        this.attachments = data;
                        return [3, 7];
                    case 6:
                        error_1 = _a.sent();
                        console.log('error', error_1);
                        return [3, 7];
                    case 7: return [2];
                }
            });
        });
    };
    AttachmentsTab.prototype.getImagePermission = function (url) {
        return __awaiter(this, void 0, void 0, function () {
            var base64;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.$functions.httpsCallable('getImagePermission')({
                            url: url,
                            userId: this.$store.state.user.uid
                        })];
                    case 1:
                        base64 = (_a.sent()).data.base64;
                        return [2, base64];
                }
            });
        });
    };
    AttachmentsTab.prototype.getBucket = function () {
        return __awaiter(this, void 0, void 0, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.$functions.httpsCallable('getBucket')({})];
                    case 1:
                        data = (_a.sent()).data;
                        return [2, data];
                }
            });
        });
    };
    AttachmentsTab.prototype.browseFiles = function () {
        if (this.checkIssueIsOpen) {
            ;
            this.$refs.input.click();
        }
    };
    AttachmentsTab.prototype.onChange = function (event) {
        var target = event.target;
        var file = target.files[0];
        if (target.files && target.files.length) {
            this.uploadedFile = file;
            this.transformFile();
        }
    };
    AttachmentsTab.prototype.dropFile = function (event) {
        if (this.checkIssueIsOpen) {
            event.preventDefault();
            if (event.dataTransfer.items && event.dataTransfer.items.length === 1) {
                this.uploadedFile = event.dataTransfer.items[0].getAsFile();
            }
        }
    };
    AttachmentsTab.prototype.classAccordingDrag = function () {
        return this.checkDrag ? 'issues__draggable' : '';
    };
    AttachmentsTab.prototype.transformFile = function () {
        var _this = this;
        var reader = new FileReader();
        reader.onload = function (event) {
            _this.imageSrc = event.target.result;
        };
        reader.readAsDataURL(this.uploadedFile);
    };
    AttachmentsTab.prototype.cancelUploadImage = function () {
        this.uploadedFile = null;
        this.imageSrc = '';
        this.showFile = false;
        this.showScreenshot = false;
    };
    AttachmentsTab.prototype.checkDraggable = function () {
        if (this.checkIssueIsOpen) {
            this.checkDrag = true;
        }
    };
    AttachmentsTab.prototype.createScreenshot = function () {
        if (this.checkIssueIsOpen) {
            this.$root.$emit('createScreenshot');
        }
    };
    AttachmentsTab.prototype.pasteScreenshot = function () {
        if (this.checkIssueIsOpen) {
            this.imageSrc = this.screenShot;
            this.showScreenshot = true;
        }
    };
    Object.defineProperty(AttachmentsTab.prototype, "checkIssueIsOpen", {
        get: function () {
            return this.issue.attributes.status === 'open';
        },
        enumerable: false,
        configurable: true
    });
    AttachmentsTab.prototype.setAttachment = function () {
        return __awaiter(this, void 0, void 0, function () {
            var error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this.imageName.length > 3))
                            return [3, 5];
                        this.clicked = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        return [4, this.uploadScreenshot()];
                    case 2:
                        _a.sent();
                        return [4, this.storeImgToIssue()];
                    case 3:
                        _a.sent();
                        return [3, 5];
                    case 4:
                        error_2 = _a.sent();
                        console.log('error', error_2);
                        this.clicked = false;
                        return [3, 5];
                    case 5: return [2];
                }
            });
        });
    };
    AttachmentsTab.prototype.uploadScreenshot = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, folderId, projectId, body, data, id, upload, error_3;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 4, , 5]);
                        _a = this.$store.state.selectedModelData, folderId = _a.folderId, projectId = _a.projectId;
                        body = ForgeCalls.getBodyStorage(this.imageName + ".png", folderId, projectId);
                        return [4, this.$functions.httpsCallable('createStorage')(body)];
                    case 1:
                        data = (_b.sent()).data;
                        id = data.body.data.id;
                        return [4, this.uploadFile(id)];
                    case 2:
                        upload = _b.sent();
                        return [4, this.createVersion(upload.body, this.imageName + ".png")];
                    case 3: return [2, _b.sent()];
                    case 4:
                        error_3 = _b.sent();
                        console.log('error', error_3);
                        this.clicked = false;
                        return [3, 5];
                    case 5: return [2];
                }
            });
        });
    };
    AttachmentsTab.prototype.uploadFile = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var imgUrn, userId, imgUint8Array, formData, _a, url, config, data, error_4;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        imgUrn = id.split('/')[1];
                        userId = this.$store.state.user.uid;
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        imgUint8Array = helpers.b64ToUint8Array(this.imageSrc);
                        formData = ForgeCalls.getFormData(imgUrn, imgUint8Array, userId);
                        _a = ForgeCalls.getUploadArgs(formData._boundary), url = _a.url, config = _a.config;
                        return [4, axios.post(url, formData, config)];
                    case 2:
                        data = (_b.sent()).data;
                        console.log('uploadFile', data);
                        this.uploadUrn = data.body.objectId;
                        return [2, data];
                    case 3:
                        error_4 = _b.sent();
                        console.log('error', error_4);
                        this.clicked = false;
                        return [3, 4];
                    case 4: return [2];
                }
            });
        });
    };
    AttachmentsTab.prototype.createVersion = function (_a, imageName) {
        var objectId = _a.objectId;
        return __awaiter(this, void 0, void 0, function () {
            var _b, folderId, projectId, versionBody, data, error_5;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _b = this.$store.state.selectedModelData, folderId = _b.folderId, projectId = _b.projectId;
                        versionBody = ForgeCalls.getVersionBody(imageName, folderId, objectId);
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 3, , 4]);
                        return [4, this.$functions.httpsCallable('createVersion')({
                                versionBody: versionBody,
                                projectId: projectId,
                                imageName: imageName
                            })];
                    case 2:
                        data = (_c.sent()).data;
                        console.log('createVersion', data);
                        return [2, data];
                    case 3:
                        error_5 = _c.sent();
                        console.log('error', error_5);
                        this.clicked = false;
                        return [3, 4];
                    case 4: return [2];
                }
            });
        });
    };
    AttachmentsTab.prototype.storeImgToIssue = function () {
        return __awaiter(this, void 0, void 0, function () {
            var userId, uploadedUrn, error_6;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        userId = this.$store.state.user.uid;
                        uploadedUrn = this.uploadUrn;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        return [4, screenshotToIssue(__assign({ userId: userId,
                                uploadedUrn: uploadedUrn, url: location, issueId: this.issue.id, imageSrc: this.imageSrc, imageName: this.imageName + ".png" }, this.$store.state.selectedModelData), this.$functions)];
                    case 2:
                        _a.sent();
                        return [4, this.resetUI()];
                    case 3:
                        _a.sent();
                        return [3, 5];
                    case 4:
                        error_6 = _a.sent();
                        console.log('error', error_6);
                        this.clicked = false;
                        return [3, 5];
                    case 5: return [2];
                }
            });
        });
    };
    AttachmentsTab.prototype.resetUI = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.imageSrc = '';
                        this.imageName = '';
                        this.showFile = false;
                        this.showScreenshot = false;
                        return [4, this.takeIssueAttachments()];
                    case 1:
                        _a.sent();
                        return [2];
                }
            });
        });
    };
    Object.defineProperty(AttachmentsTab.prototype, "accordingStatus", {
        get: function () {
            return this.clicked || this.imageName.length < 3 ? 'bim-button-attachments-clicked' : '';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AttachmentsTab.prototype, "templateIfNoAttachments", {
        get: function () {
            return !this.showFile && !this.showScreenshot && !this.attachments.length;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AttachmentsTab.prototype, "templateIfAttachmentsList", {
        get: function () {
            return !this.showFile && !this.showScreenshot && this.attachments.length > 0;
        },
        enumerable: false,
        configurable: true
    });
    AttachmentsTab.prototype.showAttachment = function (attachment) {
        this.showAttachmentViewer = true;
        this.dataForAttachmentViewer = attachment;
        console.log('this.dataForAttachmentViewer', this.dataForAttachmentViewer);
    };
    AttachmentsTab.prototype.formatDate = function (date) {
        return Moment(date).format('DD/MM/YYYY hh:mm:ss a');
    };
    AttachmentsTab.prototype.onUploadedFileChange = function () {
        if (this.uploadedFile !== null &&
            (this.uploadedFile.type === 'image/png' || this.uploadedFile.type === 'image/jpeg')) {
            this.showFile = true;
            this.transformFile();
        }
    };
    __decorate([
        Watch('uploadedFile')
    ], AttachmentsTab.prototype, "onUploadedFileChange", null);
    AttachmentsTab = __decorate([
        Component({
            components: { MiniViewer: MiniViewer },
            props: ['issueData', 'screenShot'],
            mixins: mixins
        })
    ], AttachmentsTab);
    return AttachmentsTab;
}(Vue));
export default AttachmentsTab;
